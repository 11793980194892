// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        appointmentsFullLoad: false,
        appointmentsLiteLoad: false,
        appointments: [],
    },
    actions: {
        fetchAppointments/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchAppointments', time: Date.now() / 1000})

                const params = getApiProps('appointments', args)
                this.$api.appointments.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchAppointments', inprogress: false})
                    });
            })
        },
        fetchAppointmentsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getAppointments()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {

                            commit('setAppointments', response.data)
                            commit('setAppointmentsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchAppointmentsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchAppointmentsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isAppointmentsLiteLoad) {
                    return resolve(getters.appointments.length)
                }
                dispatch('fetchAppointments', {lite: true})
                    .then((data) => {
                        commit('updateAppointments', data)
                        commit('setAppointmentsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchAppointmentsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchAppointmentsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchAppointmentsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchAppointmentsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.appointments.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.appointments.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchAppointments', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateAppointments', data)
                            })
                            .catch(() => {
                                dispatch('fetchAppointments', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setAppointmentsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchAppointmentsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchAppointmentsChanged', inprogress: false})
                })
        },
        fetchAppointmentsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isAppointmentsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchAppointmentsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchAppointments', args)
                .then((data) => {
                    commit('updateAppointments', data)
                    return dispatch('fetchAppointments', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterAppointments', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchAppointmentsChanged', inprogress: false})
                });
        },
        reloadAppointmentsAll({dispatch}, args) {
            return dispatch('fetchAppointmentsAllLite', args)
                .then(() => {
                    dispatch('fetchAppointmentsAllPages', args)
                })
        },

        saveAppointment({dispatch}, appointment) {
            let fn = (appointment.id) ? 'updateAppointment' : 'createAppointment'
            return dispatch(fn, appointment);
        },
        createAppointment({commit, dispatch}, appointment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('appointments')
                this.$api.appointments.create(appointment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateAppointment', response.data)
                            dispatch('fetchAppointmentsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateAppointment({commit, dispatch}, appointment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('appointments')
                this.$api.appointments.update(appointment.id, appointment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateAppointment', response.data)
                            dispatch('fetchAppointmentsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteAppointment({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('appointments')
                this.$api.appointments.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteAppointment', id)
                            else commit('updateAppointment', response.data)
                            dispatch('fetchAppointmentsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreAppointment({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('appointments')
                this.$api.appointments.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateAppointment', response.data)
                            dispatch('fetchAppointmentsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteAppointmentPermanently({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('appointments')
                this.$api.appointments.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteAppointment', id)
                            dispatch('fetchAppointmentsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setAppointmentsFullLoad(state, FullLoad) {
            state.appointmentsFullLoad = state.appointmentsFullLoad || FullLoad
        },
        setAppointmentsLiteLoad(state, LitaLoad) {
            state.appointmentsLiteLoad = state.appointmentsLiteLoad || LitaLoad
        },

        setAppointments(state, nAppointments) {
            nAppointments = nAppointments.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nAppointments.sort(sortByName)
            state.appointments = nAppointments
        },

        updateAppointments(state, nAppointments) {
            if (!state.appointments.length) {
                nAppointments = nAppointments.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nAppointments.sort(sortByName)
                state.appointments = nAppointments
                // const chunks = arraySplitIntoChunks(nAppointments)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.appointments.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nAppointments.forEach(function (nAppointment) {
                if (nAppointment?.name) nAppointment.name_ = nAppointment.name.toLocaleLowerCase()
                let i = state.appointments.findIndex(u => (u.id == nAppointment.id))
                if (i < 0) {
                    state.appointments.push(nAppointment) //(Object.freeze(nAppointment))
                } else
                if (!state.appointmentsFullLoad || state.appointments[i][changedField] != nAppointment[changedField]) {
                    updateObjectByDiff(state.appointments[i], nAppointment)
                    // delete nAppointment.id
                    // nAppointment = {...state.appointments[i], ...nAppointment}
                    // state.appointments[i] = nAppointment //Object.freeze(nAppointment)
                }
            })

        },
        filterAppointments(state, nAppointments) {
            // let Ids = state.appointments.map(u=> u.id)
            let nIds = nAppointments.map(u => u.id)
            let removedIds = state.appointments.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.appointments.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.appointments.splice(i, 1)
                }
            })
        },
        updateAppointment(state, nAppointment) {
            if (nAppointment?.name) nAppointment.name_ = nAppointment.name.toLocaleLowerCase()
            let i = state.appointments.findIndex(u => (u.id == nAppointment.id))
            if (i < 0) {
                state.appointments.push(nAppointment) //(Object.freeze(nAppointment))
            } else
            if (!state.appointmentsFullLoad || state.appointments[i][changedField] != nAppointment[changedField]) {
                updateObjectByDiff(state.appointments[i], nAppointment)
                // delete nAppointment.id
                // nAppointment = {...state.appointments[i], ...nAppointment}
                // state.appointments[i] = nAppointment //Object.freeze(nAppointment)
            }
        },
        deleteAppointment(state, id) {
            let i = state.appointments.findIndex(u => (u.id == id))
            if (i != -1) {
                state.appointments.splice(i, 1)
            }
        },

        clearAppointments(state) {
            state.appointments = []
            state.appointmentsFullLoad = false
        },
        clearAppointmentsMaxSpeed(state) {
            state.appointments = state.appointments.map(u => {
                return {...u, changed__time: 0, max_speed: null}
            })
        },
    },
    getters: {
        isAppointmentsFullLoad(state) {
            return state.appointmentsFullLoad
        },
        isAppointmentsLiteLoad(state) {
            return state.appointmentsLiteLoad
        },

        appointments(state) {
            return state.appointments
        },
        appointmentsByIds(state) {
            return state.appointments.reduce((appointmentsByIds, appointment) => {
                appointmentsByIds[appointment.id] = appointment
                return appointmentsByIds
            }, {})
        },
        appointmentsAccessRightsByIds(state) {
            return state.appointments.reduce((appointmentsAccessRightsByIds, appointment) => {
                appointmentsAccessRightsByIds[appointment.id] = appointment?.access_right || 0
                return appointmentsAccessRightsByIds
            }, {})
        },
        appointmentsDriversByUnitId(state) {
            return state.appointments
                .reduce((out, a) => {
                    if(!out[a.unit_id]) out[a.unit_id] = []
                    out[a.unit_id].push(a)
                    return out;
                }, {})
        }
    }
}
