// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        tiveAlertPresetsFullLoad: false,
        tiveAlertPresetsLiteLoad: false,
        tiveAlertPresets: [],
        tiveAlertPresetsTriggerTypes: [],
    },
    actions: {
        fetchTiveAlertPresets/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveAlertPresets', time: Date.now() / 1000})

                const params = getApiProps('tiveAlertPresets', args)
                this.$api.tivealertpresets.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveAlertPresets', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveAlertPresetsLiteLoad', true)
                        commit('settiveAlertPresetsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveAlertPresets', inprogress: false})
                    });
            })
        },
        fetchTiveAlertPreset/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveAlertPreset', time: Date.now() / 1000})

                const params = getApiProps('tiveAlertPresets')
                this.$api.tivealertpresets.find(id,{...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveAlertPreset', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveAlertPreset', inprogress: false})
                    });
            })
        },
        fetchTiveAlertPresetTriggerTypes/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveAlertPresetTriggerTypes', time: Date.now() / 1000})

                const params = getApiProps('tiveAlertPresets', args)
                this.$api.tivealertpresets.triggerTypes({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveAlertPresetTriggerTypes', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveAlertPresetTriggerTypes', inprogress: false})
                    });
            })
        },

        saveTiveAlertPreset({dispatch}, alertPreset) {
            let fn = (alertPreset.id) ? 'updateTiveAlertPreset' : 'createTiveAlertPreset'
            return dispatch(fn, alertPreset);
        },
        createTiveAlertPreset({dispatch}, alertPreset) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveAlertPresets')
                this.$api.tivealertpresets.create(alertPreset, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            dispatch('fetchTiveAlertPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveAlertPreset({dispatch}, alertPreset) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveAlertPresets')
                this.$api.tivealertpresets.update(alertPreset.id, alertPreset, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            dispatch('fetchTiveAlertPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveAlertPreset({commit}, alertPresetId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveAlertPresets')
                this.$api.tivealertpresets.delete(alertPresetId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteTiveAlertPreset', alertPresetId)
                            else commit('updateTiveAlertPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveAlertPresetsFullLoad(state, FullLoad) {
            state.tiveAlertPresetsFullLoad = state.tiveAlertPresetsFullLoad || FullLoad
        },
        setTiveAlertPresetsLiteLoad(state, LitaLoad) {
            state.tiveAlertPresetsLiteLoad = state.tiveAlertPresetsLiteLoad || LitaLoad
        },

        setTiveAlertPresets(state, nTiveAlertPresets) {
            nTiveAlertPresets = nTiveAlertPresets.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveAlertPresets.sort(sortByName)
            state.tiveAlertPresets = nTiveAlertPresets
        },

        updateTiveAlertPresets(state, nTiveAlertPresets) {
            if (!state.tiveAlertPresets.length) {
                nTiveAlertPresets = nTiveAlertPresets.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveAlertPresets.sort(sortByName)
                state.tiveAlertPresets = nTiveAlertPresets
                // const chunks = arraySplitIntoChunks(nTiveAlertPresets)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveAlertPresets.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveAlertPresets.forEach(function (nAlertPreset) {
                if (nAlertPreset?.name) nAlertPreset.name_ = nAlertPreset.name.toLocaleLowerCase()
                let i = state.tiveAlertPresets.findIndex(u => (u.id == nAlertPreset.id))
                if (i < 0) {
                    state.tiveAlertPresets.push(nAlertPreset) //(Object.freeze(nAlertPreset))
                } else {
                //if (!state.tiveAlertPresetsFullLoad || state.tiveAlertPresets[i][changedField] != nAlertPreset[changedField]) {
                    updateObjectByDiff(state.tiveAlertPresets[i], nAlertPreset)
                    // delete nAlertPreset.id
                    // nAlertPreset = {...state.tiveAlertPresets[i], ...nAlertPreset}
                    // state.tiveAlertPresets[i] = nAlertPreset //Object.freeze(nAlertPreset)
                }
            })

        },
        updateTiveAlertPresetTriggerTypes(state, nTiveAlertPresetsTriggerType) {
            if (!state.tiveAlertPresetsTriggerTypes.length) {
                nTiveAlertPresetsTriggerType = nTiveAlertPresetsTriggerType.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveAlertPresetsTriggerType.sort(sortByName)
                state.tiveAlertPresetsTriggerTypes = nTiveAlertPresetsTriggerType
                // const chunks = arraySplitIntoChunks(nTiveAlertPresetsTriggerType)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveAlertPresets.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveAlertPresetsTriggerType.forEach(function (nAlertPresetType) {
                if (nAlertPresetType?.name) nAlertPresetType.name_ = nAlertPresetType.name.toLocaleLowerCase()
                let i = state.tiveAlertPresetsTriggerTypes.findIndex(u => (u.id == nAlertPresetType.id))
                if (i < 0) {
                    state.tiveAlertPresetsTriggerTypes.push(nAlertPresetType) //(Object.freeze(nAlertPresetType))
                } else {
                //if (!state.tiveAlertPresetsFullLoad || state.tiveAlertPresets[i][changedField] != nAlertPresetType[changedField]) {
                    updateObjectByDiff(state.tiveAlertPresetsTriggerTypes[i], nAlertPresetType)
                    // delete nAlertPresetType.id
                    // nAlertPresetType = {...state.tiveAlertPresets[i], ...nAlertPresetType}
                    // state.tiveAlertPresets[i] = nAlertPresetType //Object.freeze(nAlertPresetType)
                }
            })

        },
        filterTiveAlertPresets(state, nTiveAlertPresets) {
            // let Ids = state.tiveAlertPresets.map(u=> u.id)
            let nIds = nTiveAlertPresets.map(u => u.id)
            let removedIds = state.tiveAlertPresets.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.tiveAlertPresets.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.tiveAlertPresets.splice(i, 1)
                }
            })
        },
        updateTiveAlertPreset(state, nAlertPreset) {
            if (nAlertPreset?.name) nAlertPreset.name_ = nAlertPreset.name.toLocaleLowerCase()
            let i = state.tiveAlertPresets.findIndex(u => (u.id == nAlertPreset.id))
            if (i < 0) {
                state.tiveAlertPresets.push(nAlertPreset) //(Object.freeze(nAlertPreset))
            } else {
            //if (!state.tiveAlertPresetsFullLoad || state.tiveAlertPresets[i][changedField] != nAlertPreset[changedField]) {
                updateObjectByDiff(state.tiveAlertPresets[i], nAlertPreset)
                // delete nAlertPreset.id
                // nAlertPreset = {...state.tiveAlertPresets[i], ...nAlertPreset}
                // state.tiveAlertPresets[i] = nAlertPreset //Object.freeze(nAlertPreset)
            }
        },
        deleteTiveAlertPreset(state, alertPresetId) {
            let i = state.tiveAlertPresets.findIndex(u => (u.id == alertPresetId))
            if (i != -1) {
                state.tiveAlertPresets.splice(i, 1)
            }
        },

        clearTiveAlertPresets(state) {
            state.tiveAlertPresets = []
            state.tiveAlertPresetsFullLoad = false
        },
    },
    getters: {
        isTiveAlertPresetsFullLoad(state) {
            return state.tiveAlertPresetsFullLoad
        },
        isTiveAlertPresetsLiteLoad(state) {
            return state.tiveAlertPresetsLiteLoad
        },
        tiveAlertPresets(state) {
            return state.tiveAlertPresets
        },
        tiveAlertPresetsTriggerTypesByIds(state) {
            return state.tiveAlertPresets
                .reduce((tiveAlertPresetsByIds, alertPreset) => {
                tiveAlertPresetsByIds[alertPreset.id] = alertPreset
                return tiveAlertPresetsByIds
            }, {})
        },
        tiveAlertPresetsTriggerTypes(state) {
            return state.tiveAlertPresetsTriggerTypes
        },
        tiveAlertPresetsTypesById(state) {
            return state.tiveAlertPresetsTriggerTypes
                .reduce((tiveAlertPresetsTypesByIds, alertPreset) => {
                    tiveAlertPresetsTypesByIds[alertPreset.id] = alertPreset
                    return tiveAlertPresetsTypesByIds
                }, {})
        },
        sortedTiveAlertPresetsIds(state) {
            let tiveAlertPresets = state.tiveAlertPresets
            tiveAlertPresets.sort(sortByName)
            return tiveAlertPresets.map(u => u.id)
        },
    }
}
