<template>
    <FieldGroup__base_wrap
        class="field-group_type_combobox"
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :error="error"
        :warning="warning"
        :disabled="disabled"
    >
        <kendo-combobox
            :class="fieldGroup_input_class"
            :allow-custom="allowCustom"
            :data-items="itemsByGroup"
            :id="id"
            :text-field="field_value || null"
            :data-item-key="field_key"
            :placeholder="placeholder_text"
            :value="modelValue"
            :disabled="disabled"
            :filterable="filterable"
            :itemRender="'renderTemplate'"
            @filterchange="filterData"
            @change="setValue"
        >
            <template v-slot:renderTemplate="{ props }">
                <div :style="{...props.style}"
                     :class="{...props.itemClass, 'k-selected': props.dataItem.valueField == this.modelValue?.valueField, 'k-focus': false}"
                     @click="(ev) => props.onClick(ev)"
                >
                    <span v-if="props.dataItem?.type == 'group'" style="font-weight: 800">
                        {{ props.dataItem[this.field_value] || props.dataItem }}
                    </span>
                    <span v-else>
                        {{ props.dataItem[this.field_value] || props.dataItem }}
                    </span>
                </div>
            </template>
        </kendo-combobox>
    </FieldGroup__base_wrap>
</template>
<script>
    import {ComboBox} from '@progress/kendo-vue-dropdowns';
    import debounce from "lodash/debounce";

    export default {
        name: 'FieldGroup_k_combobox',
        emits:['update:modelValue', 'filterChangeFn'],
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'field_key': [String],
            'field_value': [String],
            'field_group': [String],
            'label': String,
            'modelValue': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'items': {
                type: Array,
                default: () => []
            },
            'disabled': {
                type: Boolean,
                default: () => false
            },
            filterable: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                allowCustom: true,
                fw: '',
            }
        },
        components: {
            'kendo-combobox': ComboBox
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            itemsByGroup() {
                let groupBy = this.items?.reduce((out , item) => {
                    let key = item[this.field_group] || 'other';
                    if(!out[key]) out[key] = [];
                    out[key].push(item);
                    return out
                }, {})
                let groups = Object.keys(groupBy);
                let vl = groups.length == 1 ?
                    groupBy[groups[0]] :
                    groups.map(g => {
                        let headerObj = {type: 'group'}
                        headerObj[this.field_value] = g
                        return [headerObj, ...groupBy[g]]
                    }).flat(Infinity)
                return vl
            }
        },
        methods: {
            setValue(v) {
                let value = v.target.value//this.field_value ? v.target.value[this.field_value] : v.target.value
                if(value?.type == 'group') return false;
                this.$emit('update:modelValue', value)
            },
            filterData(filter) {
                this.debounseChange(filter?.filter?.value)
                return this.items;
            },
        },
        mounted() {
            this.debounseChange = debounce((vl) => {
                this.$emit('filterChangeFn', vl)
            }, 300)
        },
        updated() {
            //console.log('FieldGroup_select updated', this.id, this.error)
        },
    }
</script>
