// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        driversFullLoad: false,
        driversLiteLoad: false,
        drivers: [],
    },
    actions: {
        fetchDrivers/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchDrivers', time: Date.now() / 1000})

                const params = getApiProps('drivers', args)
                this.$api.drivers.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchDrivers', inprogress: false})
                    });
            })
        },
        fetchDriversAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getDrivers()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {

                            commit('setDrivers', response.data)
                            commit('setDriversLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchDriversAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchDriversAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isDriversLiteLoad) {
                    return resolve(getters.drivers.length)
                }
                dispatch('fetchDrivers', {lite: true})
                    .then((data) => {
                        commit('updateDrivers', data)
                        commit('setDriversLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchDriversAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchDriversAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchDriversAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchDriversChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.drivers.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.drivers.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchDrivers', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateDrivers', data)
                            })
                            .catch(() => {
                                dispatch('fetchDrivers', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setDriversFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchDriversAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchDriversChanged', inprogress: false})
                })
        },
        fetchDriversChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isDriversFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchDriversChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchDrivers', args)
                .then((data) => {
                    commit('updateDrivers', data)
                    return dispatch('fetchDrivers', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterDrivers', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchDriversChanged', inprogress: false})
                });
        },
        reloadDriversAll({dispatch}, args) {
            return dispatch('fetchDriversAllLite', args)
                .then(() => {
                    dispatch('fetchDriversAllPages', args)
                })
        },

        fetchDriverPhoto({dispatch, getters}, args){
            return new Promise((resolve, reject) => {
                let id = args?.id
                if (!getters.apiToken || !id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchDriverPhoto', time: Date.now() / 1000})

                const params = getApiProps('drivers', args)
                this.$api.drivers.getPhoto(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchDriverPhoto', inprogress: false})
                    });
            })
        },

        saveDriver({dispatch}, driver) {
            let fn = (driver.id) ? 'updateDriver' : 'createDriver'
            return dispatch(fn, driver);
        },
        createDriver({commit, dispatch}, driver) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('drivers')
                this.$api.drivers.create(driver, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateDriver', response.data)
                            dispatch('fetchDriversChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateDriver({commit, dispatch}, driver) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('drivers')
                this.$api.drivers.update(driver.id, driver, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateDriver', response.data)
                            dispatch('fetchDriversChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteDriver({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('drivers')
                this.$api.drivers.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteDriver', id)
                            else commit('updateDriver', response.data)
                            dispatch('fetchDriversChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreDriver({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('drivers')
                this.$api.drivers.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateDriver', response.data)
                            dispatch('fetchDriversChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteDriverPermanently({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('drivers')
                this.$api.drivers.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteDriver', id)
                            dispatch('fetchDriversChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setDriversFullLoad(state, FullLoad) {
            state.driversFullLoad = state.driversFullLoad || FullLoad
        },
        setDriversLiteLoad(state, LitaLoad) {
            state.driversLiteLoad = state.driversLiteLoad || LitaLoad
        },

        setDrivers(state, nDrivers) {
            nDrivers = nDrivers.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nDrivers.sort(sortByName)
            state.drivers = nDrivers
        },

        updateDrivers(state, nDrivers) {
            if (!state.drivers.length) {
                nDrivers = nDrivers.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nDrivers.sort(sortByName)
                state.drivers = nDrivers
                // const chunks = arraySplitIntoChunks(nDrivers)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.drivers.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nDrivers.forEach(function (nDriver) {
                if (nDriver?.name) nDriver.name_ = nDriver.name.toLocaleLowerCase()
                let i = state.drivers.findIndex(u => (u.id == nDriver.id))
                if (i < 0) {
                    state.drivers.push(nDriver) //(Object.freeze(nDriver))
                } else
                if (!state.driversFullLoad || state.drivers[i][changedField] != nDriver[changedField]) {
                    updateObjectByDiff(state.drivers[i], nDriver)
                    // delete nDriver.id
                    // nDriver = {...state.drivers[i], ...nDriver}
                    // state.drivers[i] = nDriver //Object.freeze(nDriver)
                }
            })

        },
        filterDrivers(state, nDrivers) {
            // let Ids = state.drivers.map(u=> u.id)
            let nIds = nDrivers.map(u => u.id)
            let removedIds = state.drivers.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.drivers.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.drivers.splice(i, 1)
                }
            })
        },
        updateDriver(state, nDriver) {
            if (nDriver?.name) nDriver.name_ = nDriver.name.toLocaleLowerCase()
            let i = state.drivers.findIndex(u => (u.id == nDriver.id))
            if (i < 0) {
                state.drivers.push(nDriver) //(Object.freeze(nDriver))
            } else
            if (!state.driversFullLoad || state.drivers[i][changedField] != nDriver[changedField]) {
                updateObjectByDiff(state.drivers[i], nDriver)
                // delete nDriver.id
                // nDriver = {...state.drivers[i], ...nDriver}
                // state.drivers[i] = nDriver //Object.freeze(nDriver)
            }
        },
        deleteDriver(state, id) {
            let i = state.drivers.findIndex(u => (u.id == id))
            if (i != -1) {
                state.drivers.splice(i, 1)
            }
        },

        clearDrivers(state) {
            state.drivers = []
            state.driversFullLoad = false
        },
        clearDriversMaxSpeed(state) {
            state.drivers = state.drivers.map(u => {
                return {...u, changed__time: 0, max_speed: null}
            })
        },
    },
    getters: {
        isDriversFullLoad(state) {
            return state.driversFullLoad
        },
        isDriversLiteLoad(state) {
            return state.driversLiteLoad
        },
        driversOutputsById(state, getters) {
            return state.drivers.reduce((out, u) => {
                let lmsg = getters.driversLmsgsByIds[u.id]
                out[u.id] = (u.outputs || []).map(o => {
                    let sensor = (lmsg?.sensors || {})[o.param] || {}
                    let value = sensor.value
                    return {
                        name: o.name,
                        view: o.name > '' && o.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            }, {})
        },
        driversInputsById(state, getters) {
            return state.drivers.reduce((out, u) => {
                let lmsg = getters.driversLmsgsByIds[u.id]
                out[u.id] = (u.inputs || []).map(i => {
                    let sensor = (lmsg?.sensors || {})[i.param] || {}
                    let value = sensor.value
                    return {
                        name: i.name,
                        view: i.name > '' && i.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            }, {})
        },

        drivers(state) {
            return state.drivers
        },
        driversByIds(state) {
            return state.drivers.reduce((driversByIds, driver) => {
                driversByIds[driver.id] = driver
                return driversByIds
            }, {})
        },
        driversAccessRightsByIds(state) {
            return state.drivers.reduce((driversAccessRightsByIds, driver) => {
                driversAccessRightsByIds[driver.id] = driver?.access_right || 0
                return driversAccessRightsByIds
            }, {})
        },
        sortedDriversIds(state) {
            let drivers = state.drivers
            drivers.sort(sortByName)
            return drivers.map(u => u.id)
        },
    }
}
