// import Vue from 'vue'
import consts from "@/consts"
import {calcTimeToday, calcDateToday, getApiProps} from "@/lib/lib";
import i18n from "@/i18n";

const moment = require('moment-timezone');
// moment.tz.load({});

export default {
    state: {
        routes: [],
        popup: {show: false},
        app: {
            jsTZ: new Date().getTimezoneOffset()*60,
            today: Math.floor(Date.now() / 1000),
            date_today: new Date().toISOString().split('T')[0],
            list30Days: [],
            user: {},
            settingsValues: null,
            sectionInfo: null,
            sectionWidget: null,
            sectionPopup: null,
            groupsFavorite: [],
            groupsShowUnits: [],

            unitsicons: [],
            unitstypes: [],

            lastVisitNotifications: null,
            showMapInfo: false,
            showUserMenu: false,
            showMainList: true,
            showFilterDropdownMenu: false,
            showUnitSensors: true,
            mobileMenuOpen: false,
            shortWidth: null,
            windowWidth: 0,
        }
    },
    actions: {
        fetchAppUser({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchAppUser', time: Date.now() / 1000})

                const params = getApiProps('user')
                this.$api.user.fetch(params)
                .then((response) => {
                    if (response.status < 400 && !response.data.error) {
                        commit('updateAppUser', response.data)
                        resolve(response.data)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchAppUser', inprogress: false})
                });
            })
        },

        saveAppUser({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                let expand = [...consts.api.user.expand]
                if (user.new_password) expand.push('tokens')
                const params = {expand: expand.join()}
                //const params = getApiProps('user')

                this.$api.user.update(user, params)
                .then((response) => {
                    if (response.status < 400 && !response.data.error) {
                        if (response.data.tokens) {
                            commit('updateTokens', response.data.tokens)
                            delete response.data.tokens
                        }
                        commit('updateAppUser', response.data)
                        //!!!
                        dispatch('fetchUser', {id: user.id})
                    }
                    resolve(response)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
            })
        },

        saveAppUserSettings({commit, state}, settings) {
            return new Promise((resolve, reject) => {
                let user = {id: state.app.user.id, settings}
                const params = getApiProps('user')
                this.$api.user.update(user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        saveAppUserNotificationsSettings({commit, state}, notifications_settings) {
            return new Promise((resolve, reject) => {
                let user = {id: state.app.user.id, notifications_settings}
                const params = getApiProps('user')
                this.$api.user.update(user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        sendUserRequest({ /*commit,*/ state}, request) {
            return new Promise((resolve, reject) => {
                let user = {...request, id: state.app.user.id}
                const params = getApiProps('user')
                this.$api.user.request(user, params)
                    .then((response) => {
                        //console.error(response);
                        if (response.status < 400 && !response.data.error) {
                            //commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        fetchUserSettings({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUserSettings', time: Date.now() / 1000})

                this.$api.user.getSettings()
                .then((response) => {
                    if (response.status < 400 && !response.data.error) {
                        commit('updateUserSettings', response.data)
                        resolve(response.data)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUserSettings', inprogress: false})
                });
            })
        },

        fetchUnitsIcons({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsIcons', time: Date.now() / 1000})

                this.$api.user.getIcons()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUnitsIcons', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsIcons', inprogress: false})
                    });
            })
        },

        fetchUnitsTypes({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsTypes', time: Date.now() / 1000})

                this.$api.user.getTypes()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUnitsTypes', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsTypes', inprogress: false})
                    });
            })
        },

        fetchLocationsAddress({/*dispatch, commit, */getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                //dispatch('setLastCall', {name: 'fetchLocations', time: Date.now() / 1000})

                this.$api.locations.searchAddress(args)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    // .finally(() => {
                    //     dispatch('setLastCall', {name: 'fetchLocationsAddress', inprogress: false})
                    // });
            })
        },

        setGroupFavorite({commit}, args) {
            commit('setGroupFavorite', args)
        },
        setAllGroupsShowUnits({commit, rootState}, args) {
            args.groupIds = (!args.showUnits) ? [] : rootState.units.unitsGroups_index
            commit('setAllGroupsShowUnits', args)
        },
        setGroupShowUnits({commit}, args) {
            commit('setGroupShowUnits', args)
        },

        setSectionInfo({commit}, sectionInfo) {
            commit('setSectionInfo', sectionInfo)
        },
        setSectionWidget({commit}, sectionWidget) {
            commit('setSectionWidget', sectionWidget)
        },
        setSectionPopup({commit}, sectionPopup) {
            commit('setSectionPopup', sectionPopup)
        },
        setShowMainList({commit}, show) {
            commit('setShowMainList', show)
        },
    },
    mutations: {
        setLastVisitNotifications(state, time) {
            state.app.lastVisitNotifications = time
        },
        setShowMapInfo(state, showMapInfo) {
            state.app.showMapInfo = showMapInfo
        },
        setShortWidth(state, shortWidth) {
            if (shortWidth != state.app.shortWidth) {
                state.app.shortWidth = shortWidth
            }
        },
        setWindowWidth(state, width) {
            state.app.windowWidth = width
        },
        updateUserSettings(state, settingsValues) {
            state.app.settingsValues = settingsValues
        },
        calcDateToday(state) {
            if (!state.app.user) return
            state.app.today = calcTimeToday(state.app.user.time_zone, state.app.user.dst)
            state.app.date_today = calcDateToday(state.app.user.time_zone, state.app.user.dst)
        },
        updateAppUser(state, user) {
            if (!user) return
            user.time_zone = user.time_zone * 1
            user.dst = !!user.dst

            let timezone = state.app.user.time_zone;
            let dst = state.app.user.dst;
            state.app.user = user
            if ((user.time_zone !== timezone) || (user.dts !== dst)) {
                state.app.today = calcTimeToday(user.time_zone, user.dst)
                state.app.date_today = calcDateToday(user.time_zone, user.dst)
            }
        },

        updateUnitsIcons(state, unitsicons) {
            state.app.unitsicons = unitsicons
        },
        updateUnitsTypes(state, unitstypes) {
            state.app.unitstypes = unitstypes
        },
        setGroupFavorite(state, args) {
            if (!args.isFavorite) {
                state.app.groupsFavorite = state.app.groupsFavorite.filter(id => {
                    return id !== args.groupId
                })
            } else
            if (/*args.isFavorite &&*/ !state.app.groupsFavorite.includes(args.groupId)) {
                state.app.groupsFavorite.push(args.groupId)
            }
        },
        setGroupShowUnits(state, args) {
            if (!args.showUnits) {
                state.app.groupsShowUnits = state.app.groupsShowUnits.filter(id => {
                    return id !== args.groupId
                })
            } else
            if (/*args.showOnMap &&*/ !state.app.groupsShowUnits.includes(args.groupId)) {
                state.app.groupsShowUnits.push(args.groupId)
            }
        },
        setAllGroupsShowUnits(state, args) {
            state.app.groupsShowUnits = args.groupIds
        },

        setSectionInfo(state, sectionInfo) {
            state.app.sectionInfo = sectionInfo
        },
        setSectionWidget(state, sectionWidget) {
            state.app.sectionWidget = sectionWidget
        },
        setSectionPopup(state, sectionPopup) {
            state.app.sectionPopup = sectionPopup
        },
        showMainList(state) {
            state.app.showMainList = true
        },
        hideMainList(state) {
            state.app.showMainList = false
        },
        switchMainList(state) {
            state.app.showMainList = !state.app.showMainList
        },
        setShowMainList(state, show) {
            state.app.showMainList = !(!show)
        },
        setShowUserMenu(state, show) {
            state.app.showUserMenu = !(!show)
        },
        switchShowUserMenu(state) {
            state.app.showUserMenu = !state.app.showUserMenu
        },
        setShowFilterDropdownMenu(state, show) {
            state.app.showFilterDropdownMenu = !(!show)
        },
        switchShowFilterDropdownMenu(state) {
            state.app.showFilterDropdownMenu = !state.app.showFilterDropdownMenu
        },
        setMobileMenuOpen(state, control) {
            state.app.mobileMenuOpen = control
        },
        setRoutes(state, routes) {
            state.routes = routes
        },
        clearAppUser(state) {
            state.app.user = {}
            state.app.today = Math.floor(Date.now() / 1000)
            state.app.date_today = new Date().toISOString().split('T')[0]
        },
    },
    getters: {
        getRoutes(state) {
            return state.routes;
        },
        getRoutesMetaByName(state) {
            return state.routes.reduce((routesMetaByName, route) => {
                routesMetaByName[route.name] = route.meta
                return routesMetaByName
            }, {})
        },
        getUnitOrFirstActiveUnitId: (state, getters) => (id) => {
            id = id || localStorage.getItem(getters.getUserStorageKey + '.unit.active');
            if (getters.units?.length) {
                let unit = getters.units.find(u => u.id == id)
                id = unit?.id
                if (!unit || unit.license.isExpired) {
                    id = getters.firstActivatedUnitId
                }
            }
            return id || 0
        },
        getLastVisitNotifications(state, getters) {
            let lastVisit = state.app.lastVisitNotifications || (localStorage.getItem(getters.getUserStorageKey + '.notifications.lastVisit') * 1)
            return lastVisit
        },
        getShowMapInfo(state) {
            return state.app.showMapInfo
        },
        isShortWidth(state) {
            return state.app.shortWidth
        },
        windowWidth(state) {
            return state.app.windowWidth
        },
        getTimeFormat(state) {
            let settings = state.app.user.settings || {}
            let format = consts.timeFormats.find(f => f.php_format == settings.timeFormat) || {}
            format = format.moment_js || 'HH:mm'
            return format
        },
        getDateFormat(state) {
            let settings = state.app.user.settings || {}
            let format = consts.dateFormats.find(f => f.php_format == settings.dateFormat) || {}
            format = format.moment_js || 'YYYY-MM-DD'
            return format
        },
        getDateFormatAll(state) {
            let settings = state.app.user.settings || {}
            let format = consts.dateFormats.find(f => f.php_format == settings.dateFormat) || {}
            return format
        },
        isDealer(state) {
            return state.app.user.is_admin && state.app.user.parents && state.app.user.parents.length < 5
        },
        dealerAccess(state, getters) {
            let parentsDealer = getters.getAppUser.parents
            return getters.isDealer && !!parentsDealer && parentsDealer.length <= 2
        },
        accessAppUserByLvL(state, getters) {
            let parentsDealer = getters.getAppUser.parents
            return !!parentsDealer && parentsDealer.length <= 2
        },
        getPortalHideMenus(state, getters) {
            if (!getters.getAppUserId) return []
            return [
                ...(getters.getAppUser.hide_menu || []),
                ...(getters.getAppUser.dealers_hide_menu || []),
            ]
        },
        getPortalMenus(state, getters) {
            if (!getters.getAppUserId) return []
            let hide_menu = getters.getPortalHideMenus
            let menus = consts.menus.portal.filter(m => !hide_menu.includes('' + m.id))
            if (!getters.isDealer) {
                //menus = menus.filter(m => !m.forDealerOnly)
            }
            return menus
        },
        getAdminHideMenus(state, getters) {
            if (!getters.getAppUserId) return []
            return [
                ...(getters.getAppUser.admin_hide_menu || []),
                ...(getters.getAppUser.dealers_admin_hide_menu || []),
            ]
        },
        getAdminMenus(state, getters) {
            if (!getters.getAppUserId) return []
            let hide_menu = getters.getAdminHideMenus
            let menus = consts.menus.admin.filter(m => !hide_menu.includes('' + m.id))
            if (!getters.isDealer) {
                //menus = menus.filter(m => !m.forDealerOnly)
            }
            return menus
        },
        getAppMenus(state, getters) {
            if (!getters.getAppUserId) return []
            let menus = consts.menus[consts.app.$site] || []
            if (getters.isLimitedUser) menus = menus.filter(m => {
                return getters.getRoutesMetaByName[m.name]?.isLimited
            })

            let hide_menu = []
            if (consts.app.$isAdminSite) hide_menu = [...getters.getAdminHideMenus]
            if (consts.app.$isPortalSite) hide_menu = [...getters.getPortalHideMenus]
            if (!getters.isSuperAdmin) hide_menu.push('objs-changes')

            menus = menus
                .filter(m => !hide_menu.includes('' + m.id))
                .map(m => {
                    let route = getters.getRoutes.find(route => route.name == m.name)
                    return {
                        ...m,
                        ...route,
                    }
                })
            return menus
        },
        getAppLang() {
            return i18n.global.locale
        },
        getAppUserSettings(state) {
            return state.app.user.settings || {}
        },
        getAppUserUnits(state) {
            let UnitsOfMeasures = (state.app.user.settings || {}).UnitsOfMeasure || {}
            UnitsOfMeasures = {...consts.unitsOfMeasures, ...UnitsOfMeasures}
            return Object.fromEntries(
                Object.entries(UnitsOfMeasures).map(([key, val]) => [key.replace('Units', ''), val])
            )
        },
        getAppUserUnitsValue(_, getters) {
            return Object.values(getters.getAppUserUnits)
        },
        getSettingsValues(state) {
            return state.app.settingsValues
        },
        getAppUser(state) {
            return state.app.user
        },
        getAppUserId(state) {
            return state.app.user.id || 0
        },
        isSuperAdmin(state) {
            return !!state.app.user.is_super_admin
        },
        isLimitedUser(state) {
            return !!state.app.user.is_limited //!!state.app.user.is_limited
        },
        getUserStorageKey(state, getters) {
            let userId = getters.getAppUserId
            return process.env.VUE_APP_PRODUCT+'.user.'+userId
        },

        isDST(state) {
            let utc = moment().unix() - (state.app.user.time_zone)
            return moment(utc * 1000).tz("Europe/London").isDST();
        },
        getUserTimeZone(state, getters) {
            return state.app.user.time_zone * 1 + (state.app.user.dst && getters.isDST ? 3600 : 0);
        },
        getTimeToday(state) {//, getters
            return state.app.today
            // let tz = getters.getUserTimeZone/3600
            // return moment().utcOffset(tz).startOf('day').unix();
        },
        getDateToday(state) {//, getters
            return state.app.date_today
            // let tz = getters.getUserTimeZone/3600
            // return moment().utcOffset(tz).startOf('day').format('YYYY-MM-DD')
            // return moment(state.app.today*1000).utcOffset(tz).format('YYYY-MM-DD')
        },
        getAppUserList30Days(state, getters) {
            let dates = [];
            if (!state.app.user) return dates
            let tz = getters.getUserTimeZone / 3600
            let today = getters.getTimeToday
            let seconds = today + 12 * 3600
            while (dates.length < 30) {
                dates.push({
                    //date: new Date(seconds * 1000).toISOString().split('T')[0],
                    date: moment(seconds * 1000).utcOffset(tz).format('YYYY-MM-DD'),
                })
                seconds -= (24 * 3600)
            }
            return dates
        },

        getAppUserList7Days(state, getters) {
            let dates = [];
            if (!state.app.user) return dates
            let tz = getters.getUserTimeZone / 3600
            let today = getters.getTimeToday
            let seconds = today + 12 * 3600
            while (dates.length < 7) {
                dates.push({
                    //date: new Date(seconds * 1000).toISOString().split('T')[0],
                    date: moment(seconds * 1000).utcOffset(tz).format('YYYY-MM-DD'),
                })
                seconds -= (24 * 3600)
            }
            return dates
        },

        getAppUserLetters(state) {
            if (!state.app.user || !state.app.user.login) return ''
            let name = state.app.user.login
            let letters = name.toLowerCase().match(/^([a-z])([a-z]?)[a-z]*[\W\d_]*([a-z]?).*@/)
            if (letters.length >= 3) {
                name = letters[1] + ((letters[3] > '') ? letters[3] : letters[2])
            } else {
                name = name.slice(0, 2)
            }
            return name.toUpperCase()
        },

        getUnitsIcons(state) {
            return state.app.unitsicons
        },
        getUnitsTypes(state) {
            return state.app.unitstypes
        },

        groupsFavorite(state) {
            return state.app.groupsFavorite
        },
        groupsShowUnits(state) {
            return state.app.groupsShowUnits
        },
        groupsShowUnitsByIds(state) {
            return state.app.groupsShowUnits.reduce((groupsById, id) => {
                groupsById[id] = true
                return groupsById
            }, {})
        },

        showSectionInfo(state) {
            return !(!state.app.sectionInfo)
        },
        getSectionInfo(state) {
            return state.app.sectionInfo
        },
        showSectionWidget(state) {
            return !(!state.app.sectionWidget)
        },
        getSectionWidget(state) {
            return state.app.sectionWidget
        },
        showSectionPopup(state) {
            return !(!state.app.sectionPopup)
        },
        getSectionPopup(state) {
            return state.app.sectionPopup
        },

        showMainList(state) {
            return state.app.showMainList
        },
        showUserMenu(state) {
            return state.app.showUserMenu
        },
        showFilterDropdownMenu(state) {
            return state.app.showFilterDropdownMenu
        },
        getMobileMenuOpen(state) {
            return state.app.mobileMenuOpen
        }

    }
}
