<template>
    <section class="section__body">

        <TabsControl
            class="mb__12"
            :list="filterTypesList"
            v-model="activeType"
            v-if="false"
        />

        <!--- Navigation --->
        <UnitsList_navigation />

        <!--- Container --->
        <section class="selected__container plr__12 pb__12" ref="list_container">

            <DynamicScroller
                ref="list"
                class="list dynamic-scroller-list-units"
                :items="unitsAndGroups"
                :key-field="'xid'"
                :min-item-size="44"
                :buffer="600"
            >
                <template #before>
                    <!-- start -->
                </template>

                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :size-dependencies="[]"
                        :data-index="index"
                        :data-active="active"
                        :class="item.type == 'group' ? 'list-units-scroller-group-height' : 'list-units-scroller-item-height'"
                    >
                        <component
                            :is="'UnitsList_'+item.type+'_'+$consts.app.$product"
                            v-bind="item"
                        />
                    </DynamicScrollerItem>
                </template>

                <template #after>
                    <!--  end -->
                    <SectionLoading v-if="!ready || (ready && !isUnitsLiteLoad)"/>
                    <section class="section section_type_loader loader loader_status_active"
                             v-show="isUnitsLiteLoad && !filteredUnitsIds.length"
                    >
                        <span class="loader__text">{{ $t('text.No data') }}</span>
                    </section>
                </template>
            </DynamicScroller>

        </section>
    </section>
    <Teleport to="#sectionMap">
        <SectionMapInfoWrap v-if="showMapInfo && isUnitsPage && $consts.app.$isIntuit">
            <SectionMapUnitsInfo :class_type="'section_type_map_row'" />
        </SectionMapInfoWrap>
    </Teleport>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {computed, defineAsyncComponent} from "vue";

    import UnitsList_navigation from "@/components/units/UnitsList_navigation.vue";

    import SectionMapUnitsInfo from "@/components/units/map/SectionMapUnitsInfo.vue";
    import SectionMapInfoWrap from "@/components/_base/wrappers/sectionMapInfoWrap.vue";

    //intuit
    import UnitsList_group_intuit from "@/components/units/UnitsList_group.intuit.vue"
    import UnitsList_unit_intuit from "@/components/units/UnitsList_unit.intuit.vue"
    import UnitsList_unit_xtag_intuit from "@/components/units/UnitsList_unit_xtag.intuit.vue"
    import UnitsList_unit_xlink_intuit from "@/components/units/UnitsList_unit_xlink.intuit.vue"
    import UnitsList_unit_xpower_intuit from "@/components/units/UnitsList_unit_xpower.intuit.vue"

    //prometheus
    import UnitsList_group_prometheus from "@/components/units/UnitsList_group.intuit.vue"
    import UnitsList_unit_prometheus from "@/components/units/UnitsList_unit.prometheus.vue"
    //ToDo fix
    import UnitsList_unit_xtag_prometheus from "@/components/units/UnitsList_unit.prometheus.vue"
    import UnitsList_unit_xlink_prometheus from "@/components/units/UnitsList_unit.prometheus.vue"
    import UnitsList_unit_xpower_prometheus from "@/components/units/UnitsList_unit.prometheus.vue"

    //import TabsControl from "@/components/_base/TabsControl";
    import {getMainParentRouteName} from "@/lib/lib";


    export default {
        name: "UnitsList",
        components: {
            // VueAutoVirtualScrollList,
            TabsControl: defineAsyncComponent(() => import("@/components/_base/TabsControl.vue")),
            UnitsList_navigation,
            SectionMapInfoWrap,
            SectionMapUnitsInfo,

            //intuit
            UnitsList_group_intuit,//: defineAsyncComponent(() => import("@/components/units/UnitsList_group.intuit.vue")),
            UnitsList_unit_intuit,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit.intuit.vue")),
            UnitsList_unit_xtag_intuit,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit_xtag.intuit.vue")),
            UnitsList_unit_xlink_intuit,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit_xlink.intuit.vue")),
            UnitsList_unit_xpower_intuit,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit_xpower.intuit.vue")),

            //prometheus
            UnitsList_group_prometheus,//: defineAsyncComponent(() => import("@/components/units/UnitsList_group.intuit.vue")),
            UnitsList_unit_prometheus,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit.prometheus.vue")),
            //ToDo fix
            UnitsList_unit_xtag_prometheus,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit.prometheus.vue")),
            UnitsList_unit_xlink_prometheus,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit.prometheus.vue")),
            UnitsList_unit_xpower_prometheus,//: defineAsyncComponent(() => import ("@/components/units/UnitsList_unit.prometheus.vue")),
        },
        data() {
            return {
                ready: false,
                interval: null,
                activeType: 'units',
            }
        },
        provide(){
            return {
                setRoute: computed(() => this.setRoute),
                filteredUnitsIds: computed(() => this.filteredUnitsIds)
            }
        },
        computed: {
            ...mapGetters([
                "getAppUserId",
                "units",
                "unitsGroups",
                "sortedUnitsGroupsIds",


                "unitsIdsIsActive",
                "unitsIdsIsNotActive",
                "unitsIdsIsMoving",
                "unitsIdsIsParked",
                "unitsIdsIsIgnitionOn",
                "getUserStorageKey",
                "unitsByIds",
                "unitsGroupsByIds",
                "getUnitLicenseById",

                "getUnitOrFirstActiveUnitId",
                "getVideoStatusesByUnitsIds",

                "isUnitsLiteLoad",
            ]),
            routeName(){
                return this.$route.name
            },
            mainRouteName(){
                return this.$route.meta?.mainRouteName
            },
            isUnitsPage(){
                return this.mainRouteName == this.$consts.routerNames.units.main
            },
            routeFilter(){
                return this.$route.params
            },
            routeQueryFilter(){
                return this.$route.query
            },
            filterTypesList() { //ToDo fix
                return [
                    {name: this.$t('text.Units'), value: 'units'},
                    {name: this.$t('text.Groups'), value: 'group'}
                ]
            },
            activeUnitId(){
                return !this.ready ? null : this.routeFilter.unitId || null
            },
            units_length(){
                return this.units.length
            },
            activeUnitLicense(){
                return !this.units.length
                    || this.getUnitLicenseById[this.activeUnitId]?.isNotExpired
            },
            showMapInfo(){
                return this.$route.meta.showMapInfo
            },
            setRoute() {
                return this.$route.meta?.setRoute
            },
            sortedUnitsIds() {
                let units = this.units.map(u => {
                    let id = u.id
                    let sort = 1
                    if (id == this.activeUnitId) {
                        sort = 0
                    } else
                    if(this.unitsIdsIsMoving?.includes(id) && this.unitsIdsIsActive?.includes(id)) {
                        //
                    } else
                    if (this.unitsIdsIsParked?.includes(id) && this.unitsIdsIsActive?.includes(id)){
                        sort = 2
                    } else {
                        sort = 3
                    }
                    return {id, sort, name_: u.name_}
                })

                units.sort((a, b) => {
                    return a.sort - b.sort
                })
                return units.map(u => u.id)
            },
            filteredUnitsIds() {
                let queryFilter = this.routeQueryFilter
                let routeFilter = this.routeFilter

                let units = this.units.filter(u => !u.parent__id);
                    // .filter(u => u?.access_right >= accessRight.read)
                if(routeFilter.groupId) {
                    let unitsInGroup = this.unitsGroupsByIds[routeFilter.groupId]?.units || []
                    units = units.filter(u => unitsInGroup.includes(u.id))
                }
                if(queryFilter.type) {
                    units = units.filter(u => u.type === queryFilter.type)
                }
                if(queryFilter.camera) {
                    units = units.filter(u =>
                        queryFilter.camera === 'all' ? u.video : this.getVideoStatusesByUnitsIds[u.id]?.status === queryFilter.camera
                    )
                }
                if (queryFilter.name) {
                    let name = queryFilter.name?.toLocaleLowerCase()
                    units = units.filter(u => !!u.name_?.includes(name) ||
                        (this.$isDevelopment && u.hw_type?.includes(name))
                    )
                }
                if (queryFilter.signal) {
                    let ids =
                        (queryFilter.signal === 'on') ? this.unitsIdsIsActive :
                        (queryFilter.signal === 'off') ? this.unitsIdsIsNotActive : []
                    units = units.filter(u => ids.includes(u.id))
                }
                if (queryFilter.movement) {
                    let ids =
                        (queryFilter.movement === 'moving') ? this.unitsIdsIsMoving :
                        (queryFilter.movement === 'stopped') ? this.unitsIdsIsParked :
                        (queryFilter.movement === 'ignition') ? this.unitsIdsIsIgnitionOn : []
                    units = units.filter(u => ids.includes(u.id))
                }

                return units.map(u => u.id)
            },
            unitsGroupsFiltered(){
                return this.unitsGroups.reduce((groupsFiltered, g) => {
                    let units = g.units.filter(id => {
                        return this.filteredUnitsIds.includes(id)
                    })
                    groupsFiltered.push({
                        ...g,
                        units: units,
                    })
                    return groupsFiltered
                }, [])
            },
            unitsAndGroups(){
                if(!this.ready) return []

                let groupId = -1
                let units = this.sortedUnitsIds
                    .filter(uId => this.filteredUnitsIds.includes(uId))
                    .map(uId => {
                        let type = this.unitsByIds[uId]?.hw_type
                        type = this.$consts.unitsList.useTypeForTemplate.includes(type) ? 'unit_'+type : 'unit'
                        return {xid: `${groupId}-${uId}`, groupId, type, unitId: uId}
                    })

                let count = this.sortedUnitsIds.length
                if(count != this.filteredUnitsIds.length) count = this.filteredUnitsIds.length+'/'+count

                return [
                    {xid: `${groupId}`, groupId, type: 'group', title: this.$t('text.Units'), count},
                    ...units,
                ]
            }
        },
        watch: {
            // ready: {
            //     handler (/*newVl*/ /*, oldVl*/) {
            //         //if(this.$isDevelopment) console.log("isActive", newVl, oldVl)
            //         //ToDo reset filter
            //         if(this.ready && this.activeUnitId) {
            //             //this.$nextTick().then(() => this.scrollToUnit())
            //             setTimeout(() => this.scrollToUnit(), 500)
            //         }
            //     },
            //     immediate: true
            // },
            routeName: {
                handler: function (nVal, oldVal) {
                    if(getMainParentRouteName(nVal) == this.$consts.routerNames.units.main && getMainParentRouteName(oldVal) !== this.$consts.routerNames.units.main) {
                        if(this.ready && this.isUnitsPage && this.$consts.routerNames.units.all != this.routeName) {
                            this.setActiveUnit()
                        }
                    }
                }
            },
            activeUnitId: {
                handler (newVl /*, oldVl*/) {
                    //if(this.$isDevelopment) console.log("isActive", newVl, oldVl)
                    //ToDo reset filter
                    if(newVl) {
                        this.$nextTick().then(() => this.scrollToUnit())
                        //setTimeout(() => this.scrollToUnit(), 300)
                    }
                },
                immediate: true
            },
            activeUnitLicense: {
                handler: function (newVl) {
                    if(!newVl && this.ready && this.isUnitsPage && this.$consts.routerNames.units.all != this.routeName){
                        this.setActiveUnit()
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions([
                "fetchUnits",
            ]),
            ...mapMutations([
            ]),
            scrollToUnit(){
                //let xid = '-1'+this.activeUnitId
                let scrollTo = this.unitsAndGroups.findIndex(i => i.unitId == this.activeUnitId)
                let height = this.$refs?.list_container?.clientHeight
                let itemSize = Object.values(this.$refs.list?.vscrollData?.sizes)[1]
                if (height && itemSize) {
                    scrollTo -= Math.round((height / itemSize) / 2)
                    if (scrollTo < 0) scrollTo = 0
                }
                this.$refs?.list?.scrollToItem(scrollTo)
            },
            setActiveUnit() {
                if(!this.setRoute) return false;
                let unitId = this.routeFilter.unitId || null
                unitId = this.getUnitOrFirstActiveUnitId(unitId)
                this.setRoute.unitId(this.$route, this.$router, unitId)
            }
        },
        created() {
            //if(this.$isDevelopment) console.log('UnitsList created')
        },
        mounted(){
            //if(this.$isDevelopment) console.log('UnitsList mounted')
            this.$nextTick().then(() => {this.ready = true})
            //setTimeout(() => {this.ready = true;}, 500)
        },
        updated() {
            //if(this.$isDevelopment) console.log('UnitsList updated'
            //     // , this.unitsGroupsFilteredWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            //     // , this.unitsGroupsWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            // )
        },
        activated() {
            if(this.units.length && this.isUnitsPage) {
                this.setActiveUnit()
            }

            // this.$nextTick().then(() => {this.ready = true})
            setTimeout(() => this.ready = true, this.$consts.timeoutReadyComponent)
        },
        deactivated() {
            this.ready = false
        },
    }
</script>

<style lang="scss" scoped>
.selected__container {
    height: 100%;
    overflow: hidden;
}
.list {
    background: var(--units-list-bg);
}
</style>